@tailwind base;
@tailwind components;
@tailwind utilities;

div,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}
